<vw-card customClasses="bg-white rounded-xl flex gap-4 p-3 mb-3">
  <vw-text-input
    [type]="'radio'"
    [checked]="activeTaskId === task.id"
    (click)="selectTask(task.id)"
    class="cursor-pointer"
  ></vw-text-input>
  <div>
    <p class="text-lg font-semibold leading-none  pb-[2px] text-[#0C0D0D]">
      {{ task.title }}
    </p>
    <p class="text-[#7A8581] text-sm ">
      {{ task.description }}
    </p>
    <div *ngFor="let metadata of task.metadata" class="flex items-center gap-1 mt-2">
      <div
        class="text-xs font-semibold text-[#C5132E] flex items-center gap-2 border border-[#C5132E] py-1 px-2 w-auto rounded-2xl"
      >
        {{ metadata.label || '' }}
      </div>
    </div>
    <div class="flex items-center gap-1 mt-2">
      <div
        class="text-xs font-semibold text-[#565D5B] flex items-center gap-2 border border-[#C5C9C8] py-1 px-2 w-auto rounded-2xl"
      >
        <vw-icon-svg
          [iconUrl]="task.sourceIconUrl"
          additionalClasses="cursor-pointer"
          width="14"
        ></vw-icon-svg>
        {{ task.source }}
      </div>
      <div
        class="text-xs font-semibold text-[#C5132E] flex items-center gap-2 border border-[#C5132E] py-1 px-2 w-auto rounded-2xl"
      >
        <vw-icon-svg
          [iconUrl]="task.timelineIconUrl"
          additionalClasses="cursor-pointer"
          width="14"
        ></vw-icon-svg>
        {{ task.timeline }}
      </div>
    </div>
  </div>
</vw-card>
