import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingService } from '../../../onboarding/@services/onboarding.service';
import { SelectionOptions } from '../../../onboarding/@models/selection-options.interface';
import { InteriorOptions } from '../../../onboarding/@models/interior-options.interface';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

@Component({
  selector: 'vw-custom-form', // Component selector for app-account-form
  standalone: true, // Declares the component as standalone
  imports: [SharedModule, CommonModule, FormsModule], // Modules and components required for this component
  templateUrl: './custom-form.component.html', // HTML template path
  styleUrl: './custom-form.component.scss', // Stylesheet path
})
export class CustomFormComponent implements OnInit, OnDestroy {

  @Input() isOpen: boolean = false;
  @Input() formConfig: any; // General config object
  @Output() closeBottomSheet = new EventEmitter<void>();
  @Output() submitForm = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();

  selectionOptions: SelectionOptions[] = [];
  interiorOptions: InteriorOptions[] = [];
  formData: any = {};

  constructor(private onboardingService: OnboardingService) {}

  ngOnInit(): void {
    this.loadOptions();
    this.initializeFormData();
  }

  loadOptions(): void {
    this.onboardingService.getSelectionOptions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(options => {
        this.selectionOptions = options;
      });

    this.onboardingService.getInteriorOptions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(options => {
        this.interiorOptions = options;
      });
  }

  initializeFormData(): void {
    this.formData = { ...this.formConfig.defaultValues }; // Set initial form data
  }

  closeSheet(): void {
    this.closeBottomSheet.emit();
  }

  submitAccountForm(): void {
    if (this.isFormValid()) {
      this.submitForm.emit(this.formData);
    } else {
    }
  }
  isFormValid(): boolean {
    let isValid = true;
    this.formConfig.fields.forEach((field: any) => {
      if (field.required && !this.formData[field.name]) {
        isValid = false;
        // Optionally mark the field as having an error for visual indication
        field.hasError = true;
      } else {
        field.hasError = false;
      }
    });
    return isValid;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
