<div class="relative mt-3">
  <vw-icon-svg
    [iconUrl]="article.imageUrl"
    additionalClasses=""
    width="1000"
    height="184"
  ></vw-icon-svg>

  <div class="absolute top-3 left-3 flex items-center gap-2">
    <div
      *ngFor="let badge of article.badges"
      class="text-xs font-semibold text-[#185545] flex items-center gap-1 border border-[#63AE81] py-1 px-2 rounded-xl bg-white"
    >
      <vw-icon-svg
        [iconUrl]="badge.iconUrl"
        additionalClasses="cursor-pointer"
        width="14"
        class="text-[#63AE81]"
      ></vw-icon-svg>
      {{ badge.label }}
    </div>
  </div>

  <div class="absolute bottom-3 left-3 text-white">
    <p class="text-lg font-semibold tracking-wide">{{ article.title }}</p>
    <p class="text-sm">{{ article.subTitle }}</p>
  </div>
</div>
