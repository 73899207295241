import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Plant, PlantDetailsDto } from '../@models/plant.interface';
import {
  DUMMY_Plants,
  DUMMY_Plants_Home,
  MOCK_GARDENING_TIPS,
  MOCK_PLANT_DETAILS,
  MOCK_PLANT_TASKS,
  MOCK_SEEDS,
  MOCK_VERITY_PLANT,
  MOCK_VERITY_PLANT_DTO,
  MOCK_WITHOUT_VERITY_PLANT,
} from '../@models/mock-data';
import { Seed } from '../@models/seed.interface';
import { VarietyPlant, VarietyPlantDto } from '../@models/veriety-plant.interface';
import {Task} from '../../agenda/@models/task.interface';
import {Article} from '../../home/@models/task.interface';
import {Cultivation} from '../@models/cultivation.interface';

@Injectable({
  providedIn: 'root', // This service is provided at the root level
})
export class PotagerService {
  private seeds: Seed[] = MOCK_SEEDS; // Mock seeds data
  private varietyPlants: VarietyPlant[] = MOCK_VERITY_PLANT; // Mock variety plants data
  private withoutVarietyPlants: VarietyPlant[] = MOCK_WITHOUT_VERITY_PLANT; // Mock variety plants data


  constructor() {}

  /**
   * Fetches a list of plants.
   * @returns {Observable<Plant[]>} An observable containing an array of plants.
   */
  getCultivations(name:string): Observable<Cultivation[]> {
    if (name === 'home') {
      return of(DUMMY_Plants_Home); // Returning dummy plant data as an observable
    }else{
      return of(DUMMY_Plants); // Returning dummy plant data as an observable

    }
  }

  /**
   * Fetches a list of seeds.
   * @returns {Observable<Seed[]>} An observable containing an array of seeds.
   */
  getSeeds(): Observable<Seed[]> {
    return of(MOCK_SEEDS); // Returning mock seeds data as an observable
  }

  /**
   * Fetches a list of variety plants.
   * @returns {Observable<VarietyPlant[]>} An observable containing an array of variety plants.
   */
  getVarietyPlant(): Observable<VarietyPlant[]> {
    return of(MOCK_VERITY_PLANT); // Returning mock variety plants data as an observable
  }

  /**
   * Fetches a seed by its ID.
   * @param id - The ID of the seed to fetch.
   * @returns {Observable<Seed | undefined>} An observable containing the seed or undefined if not found.
   */
  getSeedById(id: number): Observable<Seed | undefined> {
    const seed = this.seeds.find(s => s.id === id); // Find seed by ID
    return of(seed); // Return as observable
  }

  /**
   * Fetches a variety plant by its ID.
   * @param id - The ID of the plant to fetch.
   * @returns {Observable<VarietyPlant | undefined>} An observable containing the plant or undefined if not found.
   */
  getVarietyPlantById(id: number, name:string): Observable<VarietyPlant | undefined> {
    const plant = this.varietyPlants.find(p => p.id === id); // Find plant by ID
    return of(plant); // Return as observable
  }
  getPlantWithoutVarietyById(id: number, name: string): Observable<VarietyPlant | undefined> {
    // Find plant by matching both id and name
    const plant = this.withoutVarietyPlants.find(p =>  p.name === name);
    return of(plant); // Return the result as an observable
  }

  /**
   * Fetches a list of variety plants DTO.
   * @returns {Observable<VarietyPlantDto[]>} An observable containing an array of variety plant DTOs.
   */
  getVarietyPlantsDTO(): Observable<VarietyPlantDto[]> {
    return of(MOCK_VERITY_PLANT_DTO); // Returning mock variety plant DTOs as an observable
  }

  /**
   * Fetches a list of plant tasks.
   * @returns {Observable<PlantTask[]>} An observable containing an array of plant tasks.
   */
  getPlantTasks(): Observable<Task[]> {
    return of(MOCK_PLANT_TASKS); // Returning mock plant tasks as an observable
  }

  /**
   * Fetches a list of gardening tips.
   * @returns {Observable<GardeningTip[]>} An observable containing an array of gardening tips.
   */
  getGardeningTips(): Observable<Article[]> {
    return of(MOCK_GARDENING_TIPS); // Returning mock gardening tips as an observable
  }

  /**
   * Fetches plant details.
   * @returns {Observable<PlantDetailsDto>} An observable containing plant details.
   */
  getPlantDetails(): Observable<PlantDetailsDto> {
    return of(MOCK_PLANT_DETAILS); // Returning mock plant details as an observable
  }
}
