import {Component, Input} from '@angular/core';
import {SharedModule} from "../../../@commons/ui-components/shared.module";
import {Task} from '../../../agenda/@models/task.interface';
import {NgForOf} from '@angular/common';

@Component({
  selector: 'vw-task',
  standalone: true,
  imports: [
    SharedModule,
    NgForOf
  ],
  templateUrl: './task.component.html',
  styleUrl: './task.component.scss'
})
export class TaskComponent {
  @Input() task: Task;
  activeTaskId: number;

  selectTask(id: number) {

  }
}
