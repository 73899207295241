import {Plant, PlantDetailsDto} from "./plant.interface";
import {Seed} from "./seed.interface";
import {VarietyPlant, VarietyPlantDto} from "./veriety-plant.interface";
import {Task} from '../../agenda/@models/task.interface';
import {Article} from '../../home/@models/task.interface';
import {Cultivation} from './cultivation.interface';

export const DUMMY_Plants: Plant[] = [
  {
    id: 1,
    name: 'Tomates',
    varietyType: 3,
    variety: '3 variétés',
    imageUrl: '/assets/svg/tomato-1.svg',
    icon: '/assets/svg/leaf2.svg',
    actionRequired: true,
  },
  {
    id: 2,
    name: 'Laitues',
    varietyType: 0,
    variety: 'Pas de variété renseignée',
    imageUrl: '/assets/svg/cobbage.svg',
    icon: '',
    actionRequired: true,
  },
  {
    id: 3,
    name: 'Potatoes',
    varietyType: 0,
    variety: 'Pas de variété renseignée',
    imageUrl: '/assets/svg/poteto.svg',
    icon: '',
    actionRequired: false,
  },
  {
    id: 4,
    name: 'Brinjal',
    varietyType: 0,
    variety: 'Variété : Black Beauty',
    imageUrl: '/assets/svg/brinjal.svg',
    icon: '/assets/svg/leaf2.svg',
    actionRequired: false,
  },
];

export const DUMMY_Plants_Home: Cultivation[] = [
  {
    id: 1,
    name: 'Tomates',
    varietyType: 3,
    variety: '3 variétés',
    imageUrl: '/assets/svg/tomato-1.svg',
    icon: '/assets/svg/Frame 2387.svg',
    actionRequired: true,
  },
  {
    id: 2,
    name: 'Laitues',
    varietyType: 0,
    variety: 'Pas de variété renseignée',
    imageUrl: '/assets/svg/cobbage.svg',
    icon: '',
    actionRequired: true,
  },

];

// mock-seeds.ts

export const MOCK_SEEDS: Seed[] = [
  {
    id: 1,
    name: 'Tomate Ronde',
    expirationDate: '06/2026',
    variety: 'Buffalo Steak',
    rating: 3,
    progress: 50,
    iconUrl: '/assets/svg/tamoto-banner.svg',
    quantityLeft: 'half a pack',
    addedDate: '01/2023',
    brand: 'Vilmorin',
  },
  {
    id: 2,
    name: 'Aubergine',
    expirationDate: '01/2027',
    variety: 'Black Beauty',
    progress: 100,
    iconUrl: '/assets/svg/dummy.svg',
    quantityLeft: 'Full pack',
    addedDate: '03/2023',
    brand: 'Burpee',
  },
  {
    id: 3,
    name: 'Tomate',
    expirationDate: '06/2026',
    variety: 'Coeur de boeuf',
    progress: 80,
    iconUrl: '/assets/svg/dummy.svg',
    quantityLeft: 'quarter pack',
    addedDate: '02/2023',
    brand: 'Ferry-Morse',
  },
];


export const MOCK_VERITY_PLANT: VarietyPlant[] = [
  {
    id: 1,
    name: 'Coeur de boeuf',
    datePlanted: '12/03/2024',
    varietyType: 3,
    type: 'Growingstep',
    location: 'Extérieur - Serre',
    growthProgress: 80,
    iconUrl: '/assets/svg/leaf-img.svg',
    brand: ''
  },
  {
    id: 2,
    name: 'Coeur de boeuf',
    datePlanted: '24/03/2024',
    varietyType: 3,
    location: 'inside - Pot',
    type: 'culture',
    growthProgress: 50,
    iconUrl: '/assets/svg/leaf-img.svg',
    brand: ''
  },
  {
    id: 3,
    name: 'Coeur de boeuf',
    datePlanted: '11/02/2024',
    varietyType: 3,
    location: 'Extérieur - Jardin',
    type: 'culture',
    growthProgress: 20,
    iconUrl: '/assets/svg/pot.svg',
    brand: '/assets/svg/vilmorin-logo.svg'
  }
];

export const MOCK_WITHOUT_VERITY_PLANT: VarietyPlant[] = [
  {
    id: 1,
    name: 'Laitues',
    datePlanted: '12/03/2024',
    type: 'Growingstep',
    location: 'Extérieur - Serre',
    growthProgress: 80,
    iconUrl: '/assets/svg/cobbage.svg',
    brand: ''
  },
  {
    id: 2,
    name: 'Potatoes',
    datePlanted: '24/03/2024',
    location: 'inside - Pot',
    type: 'culture',
    growthProgress: 50,
    iconUrl: '/assets/svg/poteto.svg',
    brand: ''
  },
  {
    id: 3,
    name: 'Brinjal',
    datePlanted: '11/02/2024',
    location: 'Extérieur - Jardin',
    type: 'culture',
    growthProgress: 20,
    iconUrl: '/assets/svg/brinjal.svg',
    brand: ''
  }
];


export const MOCK_VERITY_PLANT_DTO: VarietyPlantDto[] = [
  {
    id: 1,
    name: "Tomate Coeur de Boeuf",
    growingStep: "Culture",
    status: "In progress",
    growthProgress: 30,
    period: ["De janvier à août", "D’octobre à novembre"],
    plantAspect: "Attractive green plant with first leaves visible.",
    needs: [
      {
        iconUrl: "/assets/svg/sun2.svg",
        description: "Plein soleil, au moins 6-8 heures par jour.",
      },
      {
        iconUrl: "/assets/svg/rain.svg",
        description: "Arrosage régulier, 3 times a week",
      },
    ],
  },
  {
    id: 2,
    name: "Basilic",
    growingStep: "Culture",
    status: "Select",
    growthProgress: 0,
    period: ["De mars à avril", "De juillet à septembre"],
    plantAspect: "Green leaves with aromatic scent.",
    needs: [
      {
        iconUrl: "/assets/svg/sun2.svg",
        description: "Full sunlight, 4-6 hours daily.",
      },
      {
        iconUrl: "/assets/svg/rain.svg",
        description: "Water daily during hot months.",
      },
    ],
  },
  {
    id: 2,
    name: "Basilic",
    growingStep: "Culture",
    status: "Selected",
    growthProgress: 0,
    period: ["De mars à avril", "De juillet à septembre"],
    plantAspect: "Green leaves with aromatic scent.",
    needs: [
      {
        iconUrl: "/assets/svg/sun2.svg",
        description: "Full sunlight, 4-6 hours daily.",
      },
      {
        iconUrl: "/assets/svg/rain.svg",
        description: "Water daily during hot months.",
      },
    ],
  }
  // Add more entries as needed
];

export const MOCK_PLANT_TASKS: Task[] = [
  {
    id: 1,
    title: "Arroser les tomates",
    description: "Description brève de la tâche",
    isSelected: false,
    metadata: [{
      iconUrl: "/assets/svg/calendar.svg",
      label: "Cette semaine",
    }],
    timelineIconUrl: "/assets/svg/calendar.svg",
    sourceIconUrl: "/assets/svg/hexagon.svg",
    infoIconUrl: '',
  },
  {
    id: 2,
    title: "Arroser les tomates",
    description: "Description brève de la tâche",
    isSelected: false,
    metadata: [{
      iconUrl: "/assets/svg/calendar.svg",
      label: "Cette semaine",
    }], // No metadata for this task,
    timelineIconUrl: "/assets/svg/calendar.svg",
    sourceIconUrl: "/assets/svg/hexagon.svg",
    iconUrl: ''
  },
];

export const MOCK_GARDENING_TIPS: Article[] = [
  {
    title: 'Attention aux limaces',
    subTitle: 'Septembre, Octobre',
    imageUrl: '/assets/svg/greenIMG.svg',
    iconUrl: '',
    badges: [
      {
        iconUrl: '/assets/svg/hexagongreen.svg',
        label: 'Source',
        color: '#63AE81',
        borderColor: '#63AE81',
      },
      {
        iconUrl: '/assets/svg/hexagongreen.svg',
        label: 'Choux',
        color: '#63AE81',
        borderColor: '#63AE81',
      },
    ],
    dateRange: 'Septembre, Octobre',
  }
  // Additional advice items as needed
];


export const MOCK_PLANT_DETAILS: PlantDetailsDto = {
  name: "Tomate Coeur de Boeuf",
  description: `Tomatoes are large, herbaceous plants that need a lot of sun
                and are sensitive to frost. The huge variety of shapes, colors,
                and sizes invites trial and error. It's important to follow the
                cultivation and maintenance instructions specific to each variety.`,
  needs: [
    {description: "Plein soleil, au moins 6-8 heures par jour.", iconUrl: "/assets/svg/sun2.svg"},
    {description: "Arrosage régulier, 3 times a week", iconUrl: "/assets/svg/rain.svg"},
    {description: "Sol frais et riche en humus", iconUrl: "/assets/svg/globe.svg"},
    {description: "Profondeur : 0.5 cm", iconUrl: "/assets/svg/log-in.svg"},
    {description: "40-50 cm entre chaque plant", iconUrl: "/assets/svg/move.svg"},
  ],
  periods: ["Septembre", "Octobre"],
  friends: [
    {name: "Basilic", type: "friend", iconUrl: "/assets/svg/heart.svg"},
    {name: "Carottes", type: "friend", iconUrl: "/assets/svg/heart.svg"},
    {name: "Ognons", type: "friend", iconUrl: "/assets/svg/heart.svg"},
    {name: "Pomme de terre", type: "foe", iconUrl: "/assets/svg/red-x.svg"},
    {name: "Lorem", type: "foe", iconUrl: "/assets/svg/red-x.svg"},
    {name: "Ipsum", type: "foe", iconUrl: "/assets/svg/red-x.svg"},
  ],
  risks: [
    {name: "Mildiou", iconUrl: "/assets/svg/info-g.svg"},
    {name: "Temps sec", iconUrl: "/assets/svg/info-g.svg"},
  ],
};
