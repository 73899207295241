<vw-toast-popup
  [isOpen]="isOpen"
  height="92%"
  width="100%"
  borderRadius="20px"
  customClass="bg-white"
>
  <div
    (click)="closeSheet()"
    class="cursor-pointer w-auto text-right flex justify-end fixed right-3 top-3"
  >
    <vw-icon-svg
      iconUrl="/assets/svg/close2.svg"
      additionalClasses="cursor-pointer"
      width="24px"
      height="24px"
    ></vw-icon-svg>
  </div>
  <div class="h-full">
    <div class="w-full p-3 pb-6 pt-16">
      <h2 class="text-primary-dark text-[24px] font-semibold">{{ formConfig.title }}</h2>
      <p class="text-base">{{ formConfig.description }}</p>
    </div>
    <div class="border-t border-[#DFE2E1] h-[77%] flex flex-col justify-between">
      <div class="px-4 py-6 flex items-center gap-4 w-full">
        <div class="flex flex-col gap-6 w-full">
          <div *ngFor="let field of formConfig.fields">
            <div class="w-full relative">
              <span class="text-xs font-normal text-[#9BA6A1] pb-2 flex">{{ field.label }}</span>
              <ng-container [ngSwitch]="field.type">
                <vw-text-input
                  *ngSwitchCase="'date'"
                  [type]="'date'"
                  [placeholder]="field.placeholder"
                  [(ngModel)]="formData[field.name]"
                  class="type-date w-full"
                ></vw-text-input>

                <vw-dropdown
                  *ngSwitchCase="'dropdown'"
                  [options]="field.options === 'interior' ? interiorOptions : selectionOptions"
                  [(ngModel)]="formData[field.name]"
                  [placeholder]="field.placeholder"
                ></vw-dropdown>
              </ng-container>

              <!-- Display validation error message -->
              <div *ngIf="field.hasError" class="text-red-500 text-sm absolute pt-1">
                {{ field.label }} is required.
              </div>
            </div>
          </div>

        </div>
      </div>
      <vw-button
        (click)="submitAccountForm()"
        label="{{ formConfig.submitButtonLabel }}"
        [icon]="'/assets/svg/save2.svg'"
        [iconPosition]="'left'"
        [color]="'bg-primary hover:bg-black'"
        [textColor]="'text-white hover:text-white'"
        customClass="text-center m-auto w-auto z-[999] relative mb-8"
      >
      </vw-button>
    </div>
  </div>
</vw-toast-popup>
